<template>
  <div class="eatInOrder">
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
      <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
        <el-input v-model="form.agentName" placeholder="请输入归属代理商" class="mr20" style="width:160px;" clearable></el-input>
        <el-input v-model="form.userId" placeholder="请输入用户ID" class="mr20" style="width:180px;" clearable></el-input>
        <el-date-picker v-model="time" class="ml20" type="daterange" value-format='yyyy-MM-dd' range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <el-button type="primary" class="sure" @click="search">筛选</el-button>
      <el-button type="info" class="sure" @click="clear">清空</el-button>
      <el-button type="primary" class="sure" @click="exportExcle">导出</el-button>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="userId" label="用户ID" width='180px'></el-table-column>

        <el-table-column prop="userGrade" label="用户类型">

          <template slot-scope="scope">
            <span>{{(scope.row.userGrade||scope.row.userGrade==0)?userGradeList[scope.row.userGrade]:''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobilePhone" label="注册手机号"></el-table-column>

        <el-table-column prop="agentName" label="归属代理"></el-table-column>
        <el-table-column prop="createTime" label="提交申请时间" width="160">

        </el-table-column>
        <el-table-column prop="cashOutAmount" label="提现金额"></el-table-column>
        <el-table-column prop="cashOutType" label="到账方式">
          <template slot-scope="scope">
            <span>{{scope.row.cashOutType==1?'微信零钱':'支付宝'}}</span>
          </template>
        </el-table-column>
 <el-table-column prop="transferAmount" label="实际到账金额"></el-table-column>
        <el-table-column prop="status" label="提现状态">
          <template slot-scope="scope">
            <span>{{withDrawList[scope.row.status||0]}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="failureReason" label="说明"></el-table-column>
        <el-table-column prop="contactTel" label="操作" >
          <template slot-scope="scope">
            <div v-if="scope.row.status==1||scope.row.status==2">
              <el-button type="primary" plain size="mini" @click="surePay(scope.row)">
                确认打款
              </el-button>
              <el-button type="primary" plain size="mini" @click="rejectPay(scope.row)">
                驳回
              </el-button>
            </div>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <el-dialog title="驳回申请" :visible.sync="dialogVisible" width="30%" @close="dialogVisible=false">
       <span style="vertical-align:top;padding:4px 5px;">驳回说明：</span>
      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="rejectReason"  style="display:inline-block;width:70%"/>
      <el-button type="primary" class="sure" style="margin:20px auto;display:block" @click="rejectMessage">确认驳回</el-button>
    </el-dialog>
    <el-dialog :visible.sync="widthdrawVisible" width="30%" @close="widthdrawVisible=false">
       <el-table :data="wiethdrawData" style="width: 100%" border>
           <el-table-column prop="userId" label="用户ID" width='180px'></el-table-column>
          <el-table-column prop="mobilePhone" label="电话"></el-table-column>
       <el-table-column prop="cashOutAmount" label="提现金额"></el-table-column>
        <el-table-column prop="cashOutType" label="到账方式">
          <template slot-scope="scope">
            <span>{{scope.row.cashOutType==1?'微信零钱':'支付宝'}}</span>
          </template>
        </el-table-column>
       </el-table>
       <el-button type="primary" size="default" @click="surePayOprate" style="margin:20px auto;display:block">确认打款</el-button>
       
    </el-dialog>
  </div>
</template>
<script>
import {
  $sg_userAccountCashOutOrders, $sg_auditAction,
} from '@/api/buyRebate';
import { exportFileSync } from '@base/utils';

const withDrawList = ['', '等待打款', '已审核', '打款成功', '打款失败', '申请驳回'];
const userGradeList = ['游客', '会员', '达人', ''];
export default {
  data() {
    return {
      form: {
        userId: '',
        agentName: '',
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      branchOfficeList: [],
      loading: false,
      withDrawList,
      userGradeList,
      dialogVisible: false,
      rejectReason: '',
      id: null,
      wiethdrawData: [],
      widthdrawVisible: false,
      row: {},
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {

  },
  methods: {
    // 导出列表
    exportExcle() {
      const that = this;
      
      let startTime = null; let endTime = null;
      if (this.time) {
        startTime = this.time[0] || null; endTime = this.time[1] || null;
      }
      // $sp_exportExcle({ query: this.form.query, startTime, endTime }).then(() => {

      // });
      exportFileSync(
        '/j/rebate/userAccountCashOutOrders',
        {
          ...that.form,
          startTime,
          endTime,
          isExport: 1,
        },
        'get',
        'qrcode_download.xlsx',
      ).then(() => {
      
      });
    },
    surePayOprate() {
      $sg_auditAction({ id: this.row.id, action: 1 }).then(() => {
        this.widthdrawVisible = false;
      });
    },
    surePay(item) {
      this.wiethdrawData = [];
      this.wiethdrawData.push(item);
      this.widthdrawVisible = true;
      this.row = item;
      // this.$confirm('确认打款?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // }).then(() => {
      //   $sg_auditAction({ id: item.id, action: 1 }).then((res) => {
      //     console.log(res, '33');
      //   });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消删除',
      //   });
      // });
    },
    rejectMessage() {
      if (this.rejectReason) {
        $sg_auditAction({ id: this.id, reason: this.rejectReason, action: 2 }).then(() => {
          this.dialogVisible = false;
          this.rejectReason = '';
        });
      } else {
        this.$message.error('请输入驳回说明');
      }
    },
    rejectPay(item) {
      this.rejectReason = '';
      this.id = item.id;
      this.dialogVisible = true;
    },
    // 导出文档
    exportXcle() {
      
    },
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.form = {
        agentName: '',
        userId: '',
      };
      this.time = '';
      this.getTableData();
    },
    // 请求统计数据

    // 请求列表数据
    getTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 20,
        ...this.form,
      };
      if (this.time) {
        // if (this.time[0].getFullYear() !== this.time[1].getFullYear() || this.time[0].getMonth() !== this.time[1].getMonth()) {
        //   this.$message.error('查询时间必须在同一个月内');
        //   return;
        // }
        params.startTime = (this.time[0]);
        params.endTime = (this.time[1]);
      } else {
        params.startTime = '';
        params.endTime = '';
      }
      // 请求订单数据
      $sg_userAccountCashOutOrders(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },


    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.eatInOrder {
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  /deep/.el-dialog__header{
    text-align: center;
  }
  .orderDetail {
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #555;
    span:first-child {
      margin-right: 80px;
    }
  }
  .tableBox {
    width: 100%;
    margin-top: 40px;
  }
  .el-table {
    // min-height: 580px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
}
</style>

